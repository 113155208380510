document.addEventListener('DOMContentLoaded', function () {
  const form = document.querySelector('.form-message')
        textarea = document.querySelector('.form-message textarea'),
        submit = document.querySelector('.form-message input[type=submit]');

  const textareaEmpty = function() {
    return textarea.value.replace(/[\s\n]/g, '') == '';
  }

  const resetTextareaHeight = function() {
    const offset = textarea.offsetHeight - textarea.clientHeight;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + offset + 'px';
  }

  const updateTextareaCounter = function() {
    const maxLength = textarea.dataset.length;
    const length = textarea.value.length;
    if (length >= maxLength) {
      textarea.value = (textarea.value.substr(0, maxLength));
    }
    const counter = document.getElementById('counter-current');
    counter.textContent = length;
  }

  if (form) {
    form.addEventListener('submit', function(e) {
      if (submit.disabled || textareaEmpty()) {
        e.preventDefault();
        e.stopPropagation();
      }
    })

    form.addEventListener('ajax:success', function() {
      form.reset();
      resetTextareaHeight();
      updateTextareaCounter();
    })

    textarea.addEventListener('keydown', function(e) {
      if ((e.key === 'Enter' || e.code === 13) && !e.shiftKey) {
        if (textareaEmpty()) return;

        e.preventDefault();
        form.requestSubmit();
      }
    })

    textarea.addEventListener('keyup', function() {
      resetTextareaHeight();
      updateTextareaCounter();
      window.scrollTo(0, document.body.scrollHeight);
    });

  }
})
