import consumer from "../channels/consumer"
import Rails from "@rails/ujs"

document.addEventListener('DOMContentLoaded', function () {
  const chat = document.querySelector('.chat__messages'),
        scrollBtn = document.querySelector('.chat__scrolldown'),
        pagination = document.querySelector('.chat__pagination'),
        loading = document.querySelector('.chat__loading'),
        form = document.querySelector('.form-message'),
        state = document.querySelector('.form-message .form-message__state'),
        submit = document.querySelector('.form-message input[type=submit]');


  const isChatBottom = function() {
    const lastMsg = chat.querySelector('.message:last-child');
    const almostEnd = chat.scrollHeight - chat.offsetHeight - lastMsg.offsetHeight;
    return chat.scrollTop >= almostEnd;
  }

  const isChatTop = function() {
    return chat.scrollTop <= chat.offsetHeight;
  }

  const updateState = function(enabled) {
    if (enabled) {
      state.classList.remove('disabled');
      state.classList.add('enabled');
      submit.removeAttribute('disabled');
    } else {
      state.classList.remove('enabled');
      state.classList.add('disabled');
      submit.setAttribute('disabled', 'disabled');
    }
  }

  const loadPrevPage = function() {
    const link = pagination.querySelector('a');
    if (link) {
      loading.classList.remove('hide')

      Rails.ajax({
        type: 'GET',
        url: link.getAttribute('href'),
        dataType: 'script'
      })
    }
  }

  const loadLastPage = function() {
    loading.classList.remove('hide')

    Rails.ajax({
      type: 'GET',
      url: '/messages',
      dataType: 'script'
    })
  }


  if (chat) {
    let scrollTimeout = null;

    chat.addEventListener('scroll', function() {
      if(scrollTimeout) {
        clearTimeout(scrollTimeout);
        scrollTimeout = null;
      }
      scrollTimeout = setTimeout(function() {
        if (isChatBottom()) {
          scrollBtn.classList.add('hide');
        } else {
          scrollBtn.classList.remove('hide');
        }
        if (isChatTop()) loadPrevPage();
      }, 250)
    })

    scrollBtn.addEventListener('click', function(e) {
      e.preventDefault();
      chat.scrollTop = chat.scrollHeight;
    })

    try {
      window.chat ||= consumer.subscriptions.create({ channel: 'ChatChannel' }, {
        connected() {
          loadLastPage();
          updateState(true);
        },
        disconnected() {
          updateState(false);
        },
        rejected() {
          updateState(false);
        },
        received(data) {
          if (data['partial']) {
            const shouldScroll = isChatBottom();
            chat.querySelector('.message:last-child').insertAdjacentHTML('afterend', data['partial']);
            if (shouldScroll) chat.scrollTop = chat.scrollHeight;
          } else if(data['blocked_id']) {
            const user_messages = chat.querySelectorAll(`.message[data-user="${data['blocked_id']}"]`);
            for (let message of [...user_messages]) {
              message.innerHTML = '<div class="message__deleted">Сообщение было удалено модератором</div>'
            }
            const form = document.querySelector(`.chat[data-user="${data['blocked_id']}"] .chat__footer`)
            if (form) form.remove();
          }
        }
      })
    } catch {
      console.log('action cable is not working')
      updateState(false);
    }
  }
})
